// React eco system
import { staticFunctionMap } from '../globalConstants/cmsStaticCompInit.constant'

export const renderAEMComponents = (ref: Document | HTMLElement) => {
    Object.keys(staticFunctionMap).forEach((key: string) => {
        const singleStaticComponentInstances = ref.querySelectorAll(`.${key}`)
        singleStaticComponentInstances.forEach(el => {
            const { method, param } = staticFunctionMap[key]
            method(el, param)
        })
    })
}
