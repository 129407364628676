/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-invalid-this */

import { commonVariable } from '../globalConstants/variableDeclaration.constant'
import { NotificationItem } from '../global.type'

const bdsNotificationComponent = (): void => {
    const $ = window.$

    $(document).ready(function () {
        getNotifications()
        /**
         * Call API to get Notification results
         */
        function getNotifications() {
            const apicall = document.getElementById('UpdateNotification')
            const notificationUrl = apicall?.getAttribute('data-notificationsUrl')
            const iconPath = apicall?.getAttribute('data-iconPath')
            if (apicall && notificationUrl) {
                // eslint-disable-next-line no-void
                void $.ajax({
                    type: 'GET',
                    dataType: 'text',
                    url: notificationUrl,
                    success: function (notificationResult: any) {
                        if (notificationResult !== null) {
                            updateNotificationTemplate($.parseJSON(notificationResult), iconPath)
                        }
                    },
                })
            }
        }

        /**
         * Update Template base on response
         * @param {any} result json notificationResults
         * @param {any} iconPath iconPath
         */
        function updateNotificationTemplate(result: any, iconPath: any) {
            const iconMinimize = `<use xlink:href='${iconPath}#ct-minimizeView'></use>`
            const iconRevealMore = `<use xlink:href='${iconPath}#ct-revealMore'></use>`
            $(commonVariable.notificationDiv).html('')
            // eslint-disable-next-line sonar/no-dead-store
            let data: NotificationItem = {} as NotificationItem
            data = result.notificationItems
            const emptyNotification = result.notificationEmptyText

            if (result && result.notificationItems.length > 0) {
                $(data).each(function (_key, val: NotificationItem) {
                    const newDiv = $('<div class="cmp-version"/>').css({
                        display: 'block',
                    })

                    newDiv.appendTo($(commonVariable.notificationDiv))
                    const date = getFormatedDate(val.lastReplicated)
                    const brandName = val.brandName
                    const title = val.title
                    const topic = val.topic
                    const description = val.description
                    const path = val.path
                    const redirectButtonText = result.notificationsButtonLabel

                    const newHTML = `<button class="cmp-version__button">
                        <span>
                        <span class="cmp-version__date">
                        ${date}
                        </span>
                        <span class="cmp-version__title">
                        ${brandName}
                        <svg aria-hidden="true" class="nl-icon cmp-version__dash nl-icon--md">
                        ${iconMinimize}
                        </svg>
                        ${title}
                        <svg aria-hidden="true" class="nl-icon cmp-version__dash nl-icon--md">
                        ${iconMinimize}
                        </svg>
                        ${topic}
                        </span>
                        </span>
                        <span class="profile-button">
                        <svg aria-hidden="true" class="cmp-version__icon cmp-version__icon--plus">
                        ${iconRevealMore}
                        </svg>
                        <svg aria-hidden="true" class="cmp-version__icon cmp-version__icon--minus">
                        ${iconMinimize}
                        </svg>
                        </span>
                        </button>
                        <div class="cmp-version__panel">
                        ${description}
                        <button class="nl-banner__text-container-ctaSecondary">
                        ${redirectButtonText}
                        </button>
                        </div>`

                    $(newDiv).html(newHTML)
                    $(newDiv).on('click', '.nl-banner__text-container-ctaSecondary', function () {
                        redirect(path)
                    })
                    $(commonVariable.notificationDiv).append($(newDiv))
                })
                getToggle()
            } else {
                const newDiv = $('<div class="cmp-version noNotification"/>').css({
                    display: 'block',
                })

                newDiv.appendTo($(commonVariable.notificationDiv))
                const newHTML = emptyNotification
                $(newDiv).html(newHTML)
            }
        }

        /**
         * Update Template base on response
         * @param {any} path json notificationResults
         */
        function redirect(path: any) {
            window.open(`${path}.html`, '_blank')
        }

        /**
         * get notification date
         * @param {any} dateInMillisecond date in millisecond
         * @return {any} date in DD.MM.YY format
         */
        function getFormatedDate(dateInMillisecond: any) {
            const date = new Date(dateInMillisecond) // Date 2011-05-09T06:08:45.178Z
            const year = date.getFullYear().toString().substr(commonVariable.minusTwo)
            const month = `0${date.getMonth() + commonVariable.one}`.slice(commonVariable.minusTwo)
            const day = `0${date.getDate()}`.slice(commonVariable.minusTwo)
            return `${day}.${month}.${year}`
        }
    })

    /**
     * open and Close Accordian
     */
    function getToggle() {
        const acc = document.getElementsByClassName('cmp-version__button')
        let i
        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener('click', function (this: any) {
                this.classList.toggle('active')
                this.classList.toggle('addIcon')
                const panel = this.nextElementSibling
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null
                } else {
                    panel.style.maxHeight = `${panel.scrollHeight}px`
                }
            })
        }
    }
}

export { bdsNotificationComponent }
