/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-invalid-this */

import { commonVariable } from '../globalConstants/variableDeclaration.constant'

const bdsTabComponent = (): void => {
    const $ = window.$
    const loginContext = window.location.pathname === commonVariable.loginPath

    /**
     * Adding step for the ODP tab to create redirect cookie
     */
    if (loginContext) {
        $('[data-target-page]').click(e => {
            const targetPage = $(e.target).data('target-page')
            document.cookie = `saved_target_uri=${targetPage}; path=/`
        })
    }

    /**
     * this function will swap background and font colour based on active tab
     */
    function colorswapping() {
        if (!$(commonVariable.cmpTabActive).attr(commonVariable.attributeDataBackground)) {
            $(commonVariable.cmpTabActive).attr(commonVariable.attributeDataBackground, commonVariable.white)
        }

        const activeTabBackgroundColor =
            $(commonVariable.cmpTabActive).attr(commonVariable.attributeDataBackground) || ''

        // Text color swap
        if (['white', '#FFF', '#FFFFFF', '#E3E2E2'].includes(activeTabBackgroundColor)) {
            if (loginContext) {
                $(commonVariable.tabList).css('color', commonVariable.white)
                $(commonVariable.cmpActiveConst).css('color', commonVariable.black)
            } else {
                $(commonVariable.tabList).css('color', commonVariable.black)
            }

            $(commonVariable.tabList).css('border-color', commonVariable.black)
            $('.tabs .rich-text .title').css('color', commonVariable.black)
            $('.tabs .rich-text .content').css('color', commonVariable.black)
            $(commonVariable.loginText).css('color', commonVariable.black)
            $(commonVariable.loginTitle).css('color', commonVariable.black)
        } else {
            $(commonVariable.tabList).css('color', commonVariable.white)
            $(commonVariable.tabList).css('border-color', commonVariable.white)
            $('.tabs .rich-text .title').css('color', commonVariable.white)
            $('.tabs .rich-text .content').css('color', commonVariable.white)
            $(commonVariable.loginText).css('color', commonVariable.white)
            $(commonVariable.loginTitle).css('color', commonVariable.white)
        }

        // Color Background swap
        $('.tabs').css(commonVariable.styleBackground, '')
        $('.cmp-tabs__tablist.login-tabs .cmp-tabs__tab').css(commonVariable.styleBackground, '')
        $(commonVariable.cmpActiveConst).css(commonVariable.styleBackground, activeTabBackgroundColor)

        if (loginContext) {
            $('body').css(commonVariable.styleBackground, activeTabBackgroundColor)
        } else {
            $('.tabs').css(commonVariable.styleBackground, activeTabBackgroundColor)
        }
    }

    $(commonVariable.tabList).click(function (this: any) {
        $(commonVariable.tabList).removeClass('cmp-tabs__tab--active')
        $(this).addClass('cmp-tabs__tab--active')
        colorswapping()
    })

    $(document).ready(function () {
        setTimeout(function () {
            colorswapping()
        }, commonVariable.fifty)
    })
}

export { bdsTabComponent }
