/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-invalid-this */
/* eslint-disable array-callback-return */

import { commonVariable } from '../globalConstants/variableDeclaration.constant'

const bdsTertiaryNav = (): void => {
    const $ = window.$

    $(document).ready(function () {
        // Set each middle-content H2 content as ID
        $(commonVariable.bdsMiddleContent).each(function (this: any) {
            const text = $(this)
                .html()
                .replace(/[^A-Z0-9]+/gi, '')
                .toLowerCase()
            $(this).attr('id', text)
        })

        const parentMiddle = $(commonVariable.bdsMiddleContent).first()
        // eslint-disable-next-line sonar/no-reference-error
        const middleoffset = parentMiddle.offset() as JQuery.Coordinates
        const innerContent = $(commonVariable.bdsMiddleContent)
        const subheadings: any[] = []
        const references: any[] = []

        for (let i = 0; i < innerContent.length; i++) {
            subheadings.push(innerContent[i].innerText)
            references.push(`#${innerContent[i].id}`)
        }

        $('.bds-tertiary-navigation ul').append(
            subheadings.map((subheading, i) =>
                $("<li class='bds-tertiary-navigation__item--level-0'>").append(
                    $(`<a class=bds-tertiary-navigation__item-link href="${references[i]}">`).text(subheading),
                ),
            ),
        )

        // On Desktop, set TertiaryNav top to be the same value as the first H2 element
        if (parentMiddle.length && window.screen.width > commonVariable.mdCutOff) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            $(commonVariable.tertiaryNav).css('top', `${middleoffset?.top}px`)
        }
        // Make first element active
        $('.tertiarynavigation li > a:first').addClass('active')

        // Nav item click
        $('.tertiarynavigation li > a').click(function (this: any) {
            const targetElementId = $(this).attr('href') || ''

            $('body').animate(
                {
                    scrollTop:
                        $(targetElementId)!.offset()!.top -
                        (($('.nl-brand-navigation').outerHeight() as number) + commonVariable.tertiaryNavOffset),
                },
                commonVariable.ten,
            )
            if ($(commonVariable.tertiaryContainer).hasClass(commonVariable.tertiaryBackdrop)) {
                toggleMobileTeriaryNav()
            }
        })

        $(
            // eslint-disable-next-line max-len
            '.bds-tertiary-mobile__tertiary-toggle, .bds-tertiary-navigation__close > span, .bds-tertiary-navigation__close > .nl-icon-ct-close',
        ).click(function (this: any) {
            toggleMobileTeriaryNav()
        })

        // Nav item scroll
        const topMenu = $('.bds-tertiary-navigation__tertiarylist')
        // All list items
        const menuItems = topMenu.find('a')
        // Anchors corresponding to menu items
        // eslint-disable-next-line consistent-return
        const scrollItems = menuItems.map(function (this: any) {
            const item = $($(this).attr('href') as string)
            if (item.length) {
                return item
            }
        })

        $(window).scroll(function (this: any) {
            const topNavHeight = $('.nl-brand-navigation').outerHeight() as number
            const tertiaryNavTopPosition = topNavHeight + commonVariable.tertiaryNavOffset
            const scrollTopLimit = middleoffset.top - tertiaryNavTopPosition

            if (($(this).scrollTop() as number) > scrollTopLimit) {
                $(commonVariable.tertiaryNav).css('position', 'fixed')
                $(commonVariable.tertiaryNav).css('top', `${tertiaryNavTopPosition}px`)
            } else {
                $(commonVariable.tertiaryNav).css('position', 'absolute')
                $(commonVariable.tertiaryNav).css('top', `${middleoffset.top}px`)
            }
            // Get id of current scroll item
            const fromTop = ($(this).scrollTop() as number) + middleoffset.top
            // eslint-disable-next-line consistent-return
            let cur = scrollItems.map(function (this: any) {
                if ($(this).offset()!.top < fromTop) {
                    return this
                }
            })

            // Get the id of the current element
            cur = cur[cur.length - commonVariable.one]
            const id = cur && cur.length ? cur[0].id : ''
            // Set/remove active class
            if (($(this).scrollTop() as number) > commonVariable.fifty) {
                menuItems.removeClass('active').filter(`[href='#${id}']`).addClass('active')
            }
        })
    })

    function toggleMobileTeriaryNav() {
        $(commonVariable.tertiaryContainer).toggle()
        $(commonVariable.tertiaryContainer).toggleClass(commonVariable.tertiaryBackdrop)
        if ($(commonVariable.tertiaryContainer).hasClass(commonVariable.tertiaryBackdrop)) {
            $('body').css({ 'overflow-y': 'hidden' })
            $(commonVariable.tertiaryNav).removeAttr('style')
        } else {
            $('body').removeAttr('style')
        }
    }
}
export { bdsTertiaryNav }
